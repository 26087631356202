<template>
    <div class="mark-wrap">
        <div class="mark-header">
            <div class="title">
                <span @click="clearTag">意图分类</span>
                <div class="pagination-wrap" v-if="!dataId">
                    <div v-if="!pageInput">
                        <i @click="lastData"
                            :style="this.pages.pageIndex !== 1 ? 'cursor: pointer;' : 'cursor: not-allowed;'"
                            class="el-icon-arrow-left"></i>
                        <span style="color: rgb(22, 186, 16); cursor: pointer;" @click="pageClick">
                            {{ this.pages.pageIndex }}
                        </span> /
                        {{ this.pages.dataCount }}
                        <i @click="nextData" class="el-icon-arrow-right"
                            :style="this.pages.pageIndex !== this.pages.dataCount ? 'cursor: pointer;' : 'cursor: not-allowed;'"></i>
                    </div>
                    <div v-else style="display: flex; align-items: center;">
                        <el-button type="text" size="small" @click.native="pageGo">跳转</el-button>
                        <el-input-number :controls="false" min="1" :max="pages.dataCount" class="mark-page-input"
                            v-model="pages.pageIndex" size="mini" style="width: 35px; margin: 0 5px;" /> / {{
                    this.pages.dataCount }}
                    </div>
                </div>
            </div>
            <div class="action" v-loading="actionLoading">
                <el-radio-group v-model="contentType">
                    <el-radio class="mark-radio" border v-for="item in contentTypeData" :key="item.key"
                        :label="item.key">{{
                    item.value
                }}</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="mark-main" v-loading="listLoading" @contextmenu.prevent="openCustomMenu">
            <div id="text-container" class="text" v-html="markContent"></div>
            <div v-if="tagInfo.visible && tagList.length > 0" :class="['tag-box']"
                :style="{ top: tagInfo.top + 'px', left: tagInfo.left + 'px' }" v-clickoutside="handleClickOutside">
                <div v-for="i in tagList" :key="i.tag_id" class="tag-name" :style="{ background: i.tag_color }"
                    @click="handleSelectLabel(i)">
                    {{ i.tag_name }}
                </div>
            </div>
            <div v-if="editTag.visible" class="edit-tag"
                :style="{ top: editTag.top + 'px', left: editTag.left + 'px' }">
                <div class="py-1 bg-gray-100 text-center" @click="handleCancel">取 消</div>
            </div>
        </div>
        <div class="mark-footer">
            <el-button v-if="!dataId" type="primary" ghost @click="handleClose">
                保存当前标记页数并关闭
            </el-button>
            <el-button type="primary" :disabled="!contentType ? true : false" @click="handleSave"
                :loading="saveLoading">
                标记完成
            </el-button>
            <el-button v-if="!dataId" type="primary" :disabled="!contentType ? true : false" @click="handleSave('next')"
                :loading="saveLoading">
                完成并继续
            </el-button>
        </div>
    </div>
</template>

<script>
import Mark from 'mark.js'
import {
    getSlotListPage,
    getKnowledgeDataPageList,
    getInformationList,
    makeInformation,
    getDataInfo,
    getByNamespace
} from "../api/api";
import Clickoutside from 'element-ui/src/utils/clickoutside'
export default {
    name: 'item-component',
    props: {
        closeMarkTag: {
            type: Function,
            default: () => {
            }
        },
        dataId: Number,
    },
    directives: { Clickoutside },
    data() {
        return {
            pages: {
                pageSize: 1, //默认每页条数
                pageIndex: 1, //默认进入页
                dataCount: 1
            },
            listLoading: false,
            actionLoading: false,
            TAG_WIDTH: 780,
            selectedTextList: [],
            selectedText: {
                start: 0,
                end: 0,
                content: '',
            },
            markContent: '',
            tagInfo: {
                visible: false,
                top: 0,
                left: 0,
            },
            editTag: {
                visible: false,
                top: 0,
                left: 0,
                mark_id: '',
                content: '',
                tag_id: '',
                start: 0,
                end: 0,
            },
            tagList: [

            ],
            dataKnowledge: [],
            contentType: null,
            informationList: [],
            contentTypeData: [],
            pageInput: false,
            saveLoading: false
        }
    },
    methods: {
        handleClose() {
            localStorage.setItem('quickMarkPage', this.pages.pageIndex)
            this.closeMarkTag()
        },

        clearTag() {
            this.contentType = null
            this.selectedTextList = []
            const marker = new Mark(document.getElementById('text-container'))
            marker.unmark()
        },

        handleCancel() {
            if (!this.editTag.mark_id) return
            const markEl = new Mark(document.getElementById(this.editTag.mark_id))
            markEl.unmark()
            this.selectedTextList.splice(
                this.selectedTextList?.findIndex((t) => t.mark_id == this.editTag.mark_id),
                1
            )
            this.tagInfo = {
                visible: false,
                top: 0,
                left: 0,
            }
            this.resetEditTag()
        },
        handleKeyup(event) {
            if (event.key === 'Enter') {
                if (this.saveLoading) return
                this.handleSave('next');
            }
        },
        handlePageKeyup(event) {
            if (event.key === 'Enter') {
                this.pageGo()
            }
        },

        handleSave(type) {
            if (!this.contentType) {
                this.$message({
                    message: `意图分类不能为空`,
                    type: "error",
                });
                return
            }
            this.saveLoading = true
            let arr = []
            this.selectedTextList.map((item) => {
                arr.push({
                    slotDataText: item.mark_content,
                    slotId: item.tag_id,
                    slotTextEndIndex: item.end,
                    slotTextStartIndex: item.start
                })
            })
            let params = {
                dataId: this.informationList[0].dataId,
                contentType: this.contentType,
                slotList: arr
            }
            makeInformation(params).then((res) => {
                this.saveLoading = false
                if (res.data.success) {
                    this.contentType = null
                    this.selectedTextList = []
                    this.$message({
                        message: `保存成功`,
                        type: "success",
                    });
                    if (type === 'next') {
                        if(this.pages.pageIndex === this.pages.dataCount) return
                        this.pages.pageIndex = this.pages.pageIndex + 1
                        localStorage.setItem('quickMarkPage', this.pages.pageIndex)
                        this.getListData()
                        this.clearTag()
                    } else {
                        localStorage.setItem('quickMarkPage', this.pages.pageIndex)
                        this.closeMarkTag()
                    }
                } else {
                    this.$message({
                        message: `操作失败, ${res.data.message}`,
                        type: "error",
                    });
                }

            })

        },

        handleSelectLabel(t) {
            let that = this
            const { tag_color, tag_name, tag_id } = t
            that.tagInfo.visible = false
            const marker = new Mark(document.getElementById('text-container'))
            const markId = new Date().getTime()
            marker.markRanges(
                [
                    {
                        start: that.selectedText.start,
                        length: that.selectedText.content.length,
                    },
                ],
                {
                    className: 'text-selected',
                    element: 'span',
                    each: (element) => {
                        element.setAttribute('id', markId)
                        element.style.borderBottom = `2px solid ${t.tag_color}`
                        element.style.color = t.tag_color
                        element.style.userSelect = 'none'
                        element.style.paddingBottom = '1px'
                        element.style.position = 'relative'
                        element.dataset.content = t.tag_name
                        element.onclick = function (e) {
                            e.preventDefault()
                            if (!e.target.id) return
                            const left = e.offsetX < that.TAG_WIDTH ? 0 : e.offsetX - 100
                            const item = that.selectedTextList?.find?.((t) => t.mark_id == e.target.id)
                            const { mark_content, tag_id, start, end } = item || {}
                            that.editTag = {
                                visible: true,
                                top: e.offsetY + 40,
                                left: e.offsetX,
                                mark_id: e.target.id,
                                content: mark_content || '',
                                tag_id: tag_id || '',
                                start: start,
                                end: end,
                            }
                            that.tagInfo = {
                                visible: false,
                                top: e.offsetY + 40,
                                left: left,
                            }
                        }
                    },
                }
            )
            that.selectedTextList.push({
                tag_color,
                tag_name,
                tag_id,
                start: that.selectedText.start,
                end: that.selectedText.end,
                mark_content: that.selectedText.content,
                mark_id: markId,
            })
        },

        /**
         * 获取选取的文字数据
         */
        getSelectedTextData() {
            let that = this
            const select = window?.getSelection()
            const nodeValue = select.focusNode?.nodeValue
            const anchorOffset = select.anchorOffset
            const focusOffset = select.focusOffset
            const nodeValueSatrtIndex = that.markContent?.indexOf(nodeValue)
            that.selectedText.content = select.toString()
            if (anchorOffset < focusOffset) {
                //从左到右标注
                that.selectedText.start = nodeValueSatrtIndex + anchorOffset
                that.selectedText.end = nodeValueSatrtIndex + focusOffset
            } else {
                //从右到左
                that.selectedText.start = nodeValueSatrtIndex + focusOffset
                that.selectedText.end = nodeValueSatrtIndex + anchorOffset
            }
        },

        resetEditTag() {
            this.editTag = {
                visible: false,
                top: 0,
                left: 0,
                mark_id: '',
                content: '',
                tag_id: '',
                start: 0,
                end: 0,
            }
        },

        drawMark(res) {
            let that = this
            that.selectedTextList = res?.map((t) => ({
                tag_id: t.slotId,
                tag_name: t.slotName,
                tag_color: t.slotColor,
                start: t.slotTextStartIndex,
                end: t.slotTextEndIndex,
                mark_content: t.slotDataText,
                mark_id: new Date().getTime(),
            }))
            const markList = that.selectedTextList?.map((j) => ({
                ...j,
                start: j.start, //必备
                length: j.mark_content.length, //必备
            })) || []

            const marker = new Mark(document.getElementById('text-container'))
            markList?.forEach?.(function (m) {
                marker.markRanges([m], {
                    element: 'span',
                    className: 'text-selected',
                    each: (element) => {
                        element.setAttribute('id', m.mark_id)
                        element.style.borderBottom = `2px solid ${m.tag_color}`
                        element.style.color = m.tag_color
                        element.style.userSelect = 'none'
                        element.style.paddingBottom = '1px'
                        element.dataset.content = m.tag_name
                        element.style.position = 'relative'
                        element.onclick = function (e) {
                            const left = e.offsetX < this.TAG_WIDTH ? 0 : e.offsetX - 300
                            that.editTag = {
                                visible: true,
                                top: e.offsetY + 40,
                                left: e.offsetX,
                                mark_id: m.mark_id,
                                content: m.mark_content,
                                tag_id: m.tag_id,
                                start: m.start,
                                end: m.end,
                            }
                            that.tagInfo = {
                                visible: false,
                                top: e.offsetY + 40,
                                left: left,
                            }
                        }
                    },
                })
            })
        },
        _getSlotListPage() {
            getSlotListPage({ pageIndex: 1, pageSize: 999 }).then((res) => {
                let arr = []
                res.data.response.data.map((item) => {
                    arr.push({
                        tag_name: item.slotName,
                        tag_color: item.slotColor,
                        tag_id: item.slotId,
                    })
                })
                this.tagList = arr
            })
        },
        getKnowledgeList() {
            this.actionLoading = true
            getKnowledgeDataPageList({ pageIndex: 1, pageSize: 999 }).then((res) => {
                this.actionLoading = false
                this.dataKnowledge = res.data.response.data;
            })
        },
        _getDataInfo() {
            const that = this
            this.listLoading = true;
            var params = {
                dataId: this.dataId
            };

            this.$nextTick(() => {
                that.markContent = ''
            })
            getDataInfo(params).then((res) => {
                this.listLoading = false;
                if (res.data.success) {
                    this.informationList.push(res.data.response)
                    that.markContent = res.data.response.dataQuestion.trim()
                    that.contentType = res.data.response.contentType
                    if (res.data.response.slotList.length > 0) {
                        this.$nextTick(function () {
                            this.drawMark(res.data.response.slotList)
                        })
                    }
                }
            });
        },
        getListData() {
            const that = this
            this.listLoading = true;
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            };
            getInformationList(params).then((res) => {
                this.listLoading = false;
                this.pageInput = false
                if (res.data.response.data.length > 0) {
                    this.informationList = res.data.response.data;
                    that.contentType = res.data.response.data[0].contentType

                    that.markContent = res.data.response.data[0].dataQuestion.trim()
                    this.$nextTick(function () {
                        if (res.data.response.data[0].slotList.length > 0) {
                            this.drawMark(res.data.response.data[0].slotList)

                        }
                    })
                }
                this.pages.dataCount = res.data.response.dataCount;
                this.pages.pageIndex = res.data.response.pageIndex;
                if (res.data.response.pageSize > 0) {
                    this.pages.pageSize = res.data.response.pageSize;
                }
            });
        },
        nextData() {
            if (this.pages.pageIndex === this.pages.dataCount) return
            this.pages.pageIndex = this.pages.pageIndex + 1

            this.clearTag()
            this.getListData()

        },
        lastData() {
            if (this.pages.pageIndex === 1) return
            this.pages.pageIndex = this.pages.pageIndex - 1
            this.clearTag()
            this.getListData()

        },
        handleClickOutside() {
            if (this.tagInfo.visible) {
                this.tagInfo.visible = false
            }
        },
        openCustomMenu(e) {
            const text = window?.getSelection()
            if (text?.toString().length > 0) {
                const left = e.offsetX < this.TAG_WIDTH ? e.offsetX : e.offsetX - 100
                this.tagInfo = {
                    visible: true,
                    top: e.offsetY + 40,
                    left: left,
                }
                this.getSelectedTextData()
            } else {
                this.tagInfo.visible = false
            }
            //清空重选/取消数据
            this.resetEditTag()
        },
        getContentTypeList() {
            getByNamespace({ namespace: 'BizService.OmsAicsService.ContentType' }).then(res => {
                this.contentTypeData = res.data.response
            })
        },
        pageClick(){
            this.pageInput = true
            window.removeEventListener('keyup', this.handleKeyup);
            window.addEventListener('keyup', this.handlePageKeyup);
            
            
        },
        pageGo(){
            window.removeEventListener('keyup', this.handlePageKeyup);
            window.addEventListener('keyup', this.handleKeyup);
            this.getListData()
        }

    },
    mounted() {
        //从后端获取标注数据，进行初始化标注
        this.pages.pageIndex = parseInt(localStorage.getItem('quickMarkPage')) || 1
        this._getSlotListPage()
        this.getContentTypeList()
        if (this.dataId) {
            this._getDataInfo()
        } else {
            window.addEventListener('keyup', this.handleKeyup);
            this.getListData()
        }
    },
    destroyed() {
        // 在destroyed钩子中移除事件监听
        window.removeEventListener('keyup', this.handleKeyup);
    },

}
</script>
<style scoped>
::v-deep .mark-page-input .el-input__inner {
    padding: 0px 3px;
}

.mark-wrap {
    padding: 0 12px;
}

.mark-header {}

.mark-header .title {
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.mark-header .action {
    margin-top: 12px;
}

.mark-radio {
    margin-top: 10px;
    margin-right: 10px;
}

.mark-radio+.mark-radio {
    margin-left: 0px !important;
}

.mark-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    background: #fff;
    margin-top: 12px;
}

.mark-main {
    background: #fff;
    margin: 15px 0px;
    height: 40vh;
    padding: 12px 24px;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 13%);

}

.text {
    color: #333;
    font-weight: 500;
    font-size: 16px;
    line-height: 60px;

}

.tag-box {
    position: absolute;
    z-index: 10;
    max-height: 40vh;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 9px 28px 8px rgb(0 0 0 / 3%), 0 6px 16px 4px rgb(0 0 0 / 9%),
        0 3px 6px -2px rgb(0 0 0 / 20%);
    user-select: none;
    color: #fff;
    padding: 10px 15px;
    background-color: #fff;
}

.tag-name {
    background: rgba(243, 244, 246, var(--tw-bg-opacity));
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px 20px;
    text-align: center;
}

.tag-name+.tag-name {
    margin-top: 5px;
}

.tag-name:nth-of-type(1) {
    margin-top: 0;
}

::v-deep .text-selected::after {
    content: attr(data-content);
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    line-height: 1.2;
    top: 25px;
    /* white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center; */

}

.edit-tag {
    position: absolute;
    z-index: 20;
    padding: 16px;
    cursor: pointer;
    width: 100px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 9px 28px 8px rgb(0 0 0 / 3%), 0 6px 16px 4px rgb(0 0 0 / 9%),
        0 3px 6px -2px rgb(0 0 0 / 20%);
    user-select: none;
}

::selection {
    background: rgb(51 51 51 / 20%);
}
</style>